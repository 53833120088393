<template>
    <div>
        <div class="kbase-banner">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/kbase-banner.png" alt="">
        </div>
        <div class="kbase-container">
            <div class="base-list">
                <div class="base-item"  @click="jump('/law/list')">
                    <svg-icon iconClass="kbase-law"></svg-icon>
                    <p class="title">法律法规库</p>
                    <div class="desc">
                        <p>权威性：内容均来源于官方渠道</p>
                        <p>全面性：涵盖行业所有法律法规</p>
                        <p>时效性：及时跟进确保最新版本</p>
                        <p>易用性：词条检索轻松获取所需</p>
                    </div>
                </div>
                <div class="base-item"  @click="jump('/hazardData/list')">
                    <svg-icon iconClass="kbase-danger"></svg-icon>
                    <p class="title">隐患数据库</p>
                    <div class="desc">
                        <p>标准化：统一标准精准收集隐患</p>
                        <p>系统性：覆盖安全生产全部要素</p>
                        <p>持续性：不断优化丰富数据来源</p>
                        <p>安全性：顶级防护保障信息安全</p>
                    </div>
                </div>
                <div class="base-item"  @click="jump('/accident/list')">
                    <svg-icon iconClass="kbase-accident"></svg-icon>
                    <p class="title">事故案例库</p>
                    <div class="desc">
                        <p>多载体：文字视频解析多样呈现</p>
                        <p>高相关：精选高度相关中外案例</p>
                        <p>细分类：依据事故类别分门别类</p>
                        <p>关键词：对关键词标注简化选择</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default{
    data(){
        return{

        }
    },
    methods:{
        jump(path){
            this.$router.push({
                path:path
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.kbase-banner{
    min-width: 1200px;
    img{
        width:100%;
    }
}
.kbase-container{
    width:1200px;
    margin:-40px auto 0;
    padding-bottom: 96px;
}
.base-list{
    display: flex;
    justify-content: space-between;
}
.base-item{
    box-shadow: 0px 2px 34px 0px rgba(128,54,54,0.09);
    background: #fff;
    border-radius: 8px;
    margin-right: 38px;
    text-align: center;
    width:375px;
    padding:40px 24px 0;
    box-sizing: border-box;
    transition: all .7s ease-in-out;
    height: 345px;
    cursor: pointer;
    &::after{
        width:100%;
        position: absolute;
        width: 0;
        bottom: 0;
        height: 3px;
        left: 50%;
        transform: translateX(-50%);
        background: #E03D2F;
        content: "";
        transition: width .7s ease-in-out;
    }
    &:hover{
        transform: translateY(-15px);
        box-shadow:0px 2px 34px 0px rgba(128,54,54,0.12);
        &::after{
            width:100%;
        }
    }
    &:nth-child(3n){
        margin-right: 0;
    }
    p.title{
        margin:14px auto 24px;
        font-size: 28px;
        line-height: 28px;
        font-weight: 600;
        color: #333;
    }
    .desc p{
        font-size: 20px;
        line-height: 34px;
        color: #666;
    }
    .icon{
        font-size: 62px;
    }
}
</style>
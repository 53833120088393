<template>
  <div class="staff">
    <div class="staff-banner">
      <!-- <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/ori-banner.png">  -->
      <div class="banner-content">
        <p>2022年9月至今，山东省人民政府安全生产委员会组织开展全员定向安全生产大培训工作，取得累累硕果。此次培训主要针对易发生生产安全事故的重点行业、重点领域、重点环节、重点人群，分类实行覆盖该行业全部从业人员的精准通识安全教育培训，以进一步强化全员安全意识，掌握安全生产技能，规划人的安全行为，保障各项政策机制措施落地生效，打通政策落实的“最后一公里”，提升各生产经营单位控风险除隐患防事故的能力和水平</p>
        <button @click="toView">查看详情</button>
      </div>
    </div>
    <div class="staff-content">
      <div class="staff-content-container">
        <div class="module5 modules">
          <div class="module5-content">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/ori1.png">
            <div class="module5-content-right">
              <div class="content-item">
                <p class="title-item">截止目前，全员定向安全生产大培训，共累计培训3343908人次。分别是海上安全（74863人次）、危险化学品企业（955054人次）、工贸行业企业（1914350人次）、非煤矿山企业（368595人次）、森林草原防灭火（31046人次）</p>
              <p class="title-item">通过此次培训，山东省重点行业领域安全生产事故大幅减少，风险意识和防控能力明显提高，安全制度措施有效普及和落实，安全监管工作基础进一步落实，山东省全员定向安全生产大培训工作取得显著成效。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="module6 modules">
          <p class="title">山东省-死亡率事故率双下降</p>
          <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/line.png" class="line"> 
          <div class="module6-content">
            <p class="title-x">通过培训，2022年山东省发生各类生产安全事故1226起、死亡832人，同比下降<i class="primary">28.9％</i>和<i class="primary">26.8％</i></p>
            <div class="module6-content-container">
              <div class="text">
              <div class="item">
                <p class="title-item">工贸培训效果</p>
                <p class="content-item">钢铁、粉尘、铝加工从业人员约25万人完成了专题培训5.8万家工贸企业约160万人完成了危险作业安全管理专题培训</p>
                 <p class="content-item"></p>
                <p class="content-item">抽考覆盖全类型，合格率达到99.3％</p>
                 <p class="content-item"></p>
                <p class="content-item">事故率下降明显，2022年9至12月份，全省发生工贸事故10起、死亡12人，同比分别下降73％、50％，环比5-8月份分别下降52.4％、36.8％。</p>
              </div>
              <div class="item">
                <p class="title-item">非煤培训效果</p>
                <p class="content-item">非煤培训效果在非煤矿山领域，年终实现了事故起数下降60％、死亡人数下降90％的“双下降”目标。</p>
              </div>
            </div>
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/ori2.png"> 
            </div>
          </div>
        </div>
      </div>
      <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/staff-bg.png" class="bg"> 
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      
    }
  },
  methods:{
    toView(){
      window.open('https://dxpx.wdeduc.com/','_blank');
    },
  }
}
</script>
<style lang="scss" scoped>
.staff{
  min-width:1360px;
}
.staff-banner{
  position:relative;
  padding-bottom:148px;
  padding-top:174px;
  background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/staff/ori-banner.png') no-repeat center center;
  background-size:cover;
  img{
    width: 100%;
  }
  .banner-content{
    width:1200px;
    margin:0 auto;
    // position:absolute;
    // top:174px;
    // left:50%;
    // transform:translateX(-50%);
    p{
      color:#FDDCB8;
      font-size:22px;
      line-height:32px;
      font-weight:500;
      margin-bottom:22px;
    }
    button{
      background: linear-gradient( 180deg, #FFF6DF 2%, #F5B957 100%);
      width:215px;
      height:54px;
      line-height:54px;
      color:#fff;
      display:block;
      border:none;
      outline:none;
      font-size:26px;
      border-radius:32px;
      color:$WDPrimaryColor;
      margin:0 auto;
      cursor:pointer;
      &:hover{
        opacity:.8;
      }
    }
  }
}
.staff-content{
  background:linear-gradient(to bottom, #FFF3F1, #FFCAC1);
  position: relative;
  overflow: hidden;
  .bg{
    width:100%;
    position:relative;
    z-index:2;
    margin-top:-300px;
  }
  .staff-content-container{
    width:1220px;
    margin:0 auto;
    position:relative;
    z-index:3;s
    .modules{
      background:#fff;
      border-radius:15px;
      padding:50px 45px;
    }
    .line{
      width:907px;
      display: block;
      margin:0 auto 46px;
    }
    .title{
      font-size: 28px;
      line-height: 40px;
      color:#343434;
      text-align:center;
      padding:0 0 12px;
      font-weight: 600;
    }
    .module5{
      margin: 50px auto;
      .module5-content{
        display: flex;
        img{
          width:486px;
          vertical-align: middle;
        }
      }
      .module5-content-right{
        margin-left:40px;
        padding-top:24px;
        i{
          display: inline-block;
          width: 12px;
          height:12px;
          border:2px solid #D10000;
          margin-right:16px;
        }
        p.title-item{
          color:#353535;
          font-size:20px;
          line-height: 32px;
          font-weight:500;
          text-indent: 40px;
        }
      }
    }
    i.primary{
      color:$WDPrimaryColor;
      font-style:normal;
    }
    .module6{
      .module6-content{
        position: relative;
        .title-x{
          font-size:22px;
          line-height:28px;
          font-weight:500;
          color:#353535;
          margin-bottom:28px;
        }
        .module6-content-container{
          display:flex;
          padding:40px 44px;
          background: linear-gradient( 180deg, #FFF5F3 1%, #FFDAD1 100%);

        }
        img{
          width:376px;
          display: inline;
          margin-left:36px;
        }
        .text{
          .item:nth-child(2){
            margin-top:32px;
          }
          .title-item{
            width:156px;
            height:38px;
            color:#fff;
            background:$WDPrimaryColor;
            margin-bottom:16px;
            border-radius:32px;
            font-size:18px;
            line-height:38px;
            text-align:center;
          }
          p.content-item{
            font-size:18px;
            line-height:24px;
            min-height:24px;
            font-weight:500;
            color:#353535;
          }
          i{
            width: 11px;
            height: 11px;
            background: $WDPrimaryColor;
            display: inline-block;
            border-radius: 50%;
            margin-right:16px;
          }
        }
      }
    }
  }
}
</style>


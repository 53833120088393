<template>
    <div class="courseList">
      <div class="item" v-for="item in courseList" :key="item.id" @click="goDetail(item,1)">
        <img src="../../../assets/images/learn/new-icon.png" v-if="item.isNew" class="new-icon" alt="" />
        <div class="item-left">
          <img :src="item.coverImage" class="cover" alt="" />
          <div class="wdTag" v-if="item" :style="`background:url(https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/plan5.png) no-repeat left top;background-size:cover`">
              <p class="text">计划</p>
          </div>
        </div>
        <div class="info">
          <p class="name">
            <span>{{ item.name }}</span>
          </p>
          <p class="label">
            <span class="labelItem">
              <span>
                任务：{{ item.taskCount }}
              </span>
            </span>
          </p>
          <div class="progress">
            <el-progress color="$WDPrimaryColor" define-back-color="#E9E9E9" :show-text="false"
              :percentage="+item.studyProcess"></el-progress>
            <span class="hour">已学习{{ +item.studyProcess }}%&nbsp;&nbsp;&nbsp;{{ formateTime(item.studyTime) }}h</span>
          </div>
        </div>
        <div class="botton" :class="item.endStatus?'experat':''">
          <p class="btn" @click.stop="goDetail(item)" v-if="item.endStatus==1">
            已过期
          </p>
          <p class="btn" @click.stop="goDetail(item)" v-else>
            {{ item.studyTime > 0 ? "继续学习" : "开始学习" }}
          </p>
        </div>
      </div>
      <article v-if="noData" class="ListEmptyData">
        <img src="../../../assets/images/emptyData.png" alt />
        <p>您还没有学习内容~</p>
      </article>
      <p class="loadingmore" v-if="loading">加载中...</p>
      <p class="loadingmore" v-if="noMore">- 已加载全部内容 -</p>
    </div>
  </template>
  
  <script>
  import $ from "jquery";
  export default {
    props: {
      taskStatus: {
        type: Number,
        default: 0,
      },
      tabIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        noData: null,
        paging: {
          params: {
            pageNum: 1,
            pageSize: 10,
          },
          total: 0,
        },
        loading: false,
        courseList: [],
      };
    },
    computed: {
      noMore() {
        return (
          this.courseList.length === this.paging.total && this.paging.total !== 0
        );
      },
    },
    async created() {
      await this.findTaskList();
    },
    mounted() {
      $(window).scroll(() => {
        //判断是否滑动到页面底部
        if (
          Math.round($(window).scrollTop()) ===
          $(document).height() - $(window).height()
        ) {
          // TODO 滑动到底部时可请求下一页的数据并加载，加载可使用append方法
          // console.log('页面底部')
  
          setTimeout(this.reachBottom(), 1000);
        }
      });
    },
    methods: {
      goDetail(item) {
        if(item.endStatus==1){
          this.$message.warning('此计划已过期');
          return
        }
        this.$router.push({
          path: "/plan/detail",
          query: {
            ucode: item.ucode,
          },
        });
      },
      formateTime(val) {
        if (val) {
          if ((val / 3600).toFixed(2) > 0) {
            return (val / 3600).toFixed(2);
          } else {
            return (val / 3600).toFixed(0);
          }
        } else {
          return val;
        }
      },
      reachBottom() {
        if (
          this.paging.total > this.courseList.length
        ) {
          this.paging.params.pageNum = this.paging.params.pageNum + 1;
          this.loading = true;
          this.findTaskList("reachBottom");
        } else {
          this.loading = false;
        }
      },
      // 列表初始化
      async findTaskList() {
        let params = {
          pageNum: this.paging.params.pageNum,
          pageSize: this.paging.params.pageSize,
        };
        await this.$api.plan.findPlanList({ params }).then(async (res) => {
          if (res.data) {
            if (res.data.list && res.data.list.length) {
              this.noData = false;
            } else {
              this.noData = true;
            }
            if (this.paging.params.pageNum > 1) {
              this.courseList = this.courseList.concat(res.data.list);
            } else {
              this.courseList = res.data.list || [];
            }
            this.paging.total = res.data.total;
          }
        });
      }
    },
  };
  </script>
  
  <style lang="scss" src="../asset/css/list.scss" scoped></style>
  <style lang="scss" scoped>
  .courseList .item{
    cursor: pointer;
  }
  .wdTag{
    position: absolute;
    top: 0px;
    left: 0px;
    width:62px;
    height:30px;
    .text{
        color:#fff!important;
        font-size:12px;
        padding-left:10px;
        line-height:12px;
        margin-top:6px;
        &.employ{
            padding-left:4px;
        }
    }
}
.courseList{
  .item{
    transition: all .4s;
    &:hover{
      box-shadow: 0px 2px 34px 0px rgba(128,54,54,0.09);
      .info .name span{
        color: #D10000;
      }
    }
    .info{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .timesdate{
        font-size: 14px;
        line-height: 14px;
        color: #666;
        margin-top: 12px;;
      }
      .progress{
        flex:none;
      }
    }
  }
}
  </style>
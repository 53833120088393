<template>
    <div class="law">
        <div class="kbase-banner">
            <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/case-banner.png" alt="">
        </div>
        <div class="law-container">
            <div class="law-search">
                <div class="law-category">
                    <span v-for="item in categoryList" :key="item.id" @click="changeCategory(item)" :class="categoryId==item.categoryId?'active':''">
                        {{ item.categoryName }}
                    </span>
                </div>
                <el-form ref="searchForm" :model="searchForm" :inline="true">
                    <el-form-item label="" prop="lawName">
                        <el-input v-model="searchForm.lawName" placeholder="关键字搜索" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="" prop="nature">
                        <el-select v-model="searchForm.nature" placeholder="请选择性质" @change="search" clearable popper-class = " dxfSelect ">
                            <el-option
                                v-for="(value,key) in lawNature"
                                :key="key"
                                :label="value.text"
                                :value="key">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-select v-model="searchForm.status" placeholder="请选择状态" @change="search" clearable popper-class = " dxfSelect ">
                            <el-option
                                v-for="(value,key) in lawStatus"
                                :key="key"
                                :label="value.text"
                                :value="key"
                                >
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="search">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <ul class="lawList" v-if="list && list.length>0">
                <li v-for="item in list" :key="item.lawId" @click="handleDetail(item)">
                    <div class="top">
                        <p class="nature">{{ lawNature[item.nature]?.text }}</p>
                        <p class="status" :class="lawStatus[item.status]?.cls">{{ lawStatus[item.status]?.text }}</p>
                    </div>
                    <div class="bottom">
                        <p class="name">{{ item.lawName }}</p>
                        <p>
                            <span class="title">编号：</span>
                            <span class="desc">{{ item.standardNumber }}</span>
                        </p>
                        <p>
                            <span class="title">发布部门/单位：</span>
                            <span class="desc descPart">{{ item.issuingDepartment }}</span>
                        </p>
                    </div>
                </li>
            </ul>
            <div class="pagination" v-if="list && list.length>0">
                <el-pagination @current-change="handleCurrentChange"
                    :current-page.sync="paging.pageNum" :page-size="paging.pageSize" layout="total,prev, pager, next, jumper"
                    :total="paging.total">
                </el-pagination>
            </div>
            <empty-data text="暂无更多数据" v-if="list && list.length==0"></empty-data>
        </div>
    </div>
</template>
<script>
import emptyData from '../../../layout/empty-data.vue';
import {lawStatus,lawNature} from './../kbase.data.js'
export default{
  components: { emptyData },
    data(){
        return{
            categoryList:[],
            categoryId:'',
            lawNature,
            lawStatus,
            searchForm:{
                lawName:'',
                push:'',
                nature:'',
            },
            list:null,
             paging:{
                total: 0,
                params:{
                    pageNum: 1,
                    pageSize: 12,
                }
             }
        }
    },
    created(){
        this.getLawCategory();
    },
    methods:{
        changeCategory(item){
            this.categoryId = item.categoryId;
            this.search();
        },
        handleDetail(item) {
           this.$router.push({
                path: "/law/detail",
                query: {
                    lawId: item.lawId,
                },
            });
        },
        getLawCategory(){
            this.$api.kbase.getLawCategory().then(res=>{
                if(res.code==0){
                    this.categoryList = res.data;
                    this.categoryId = this.categoryList && this.categoryList[0]?.categoryId;
                    this.getData();
                }
            })
        },
        search(){
            this.paging.pageNum = 1;
            this.getData();
        },
        getData(){
            const params = {
                ...this.paging.params,
                ...this.searchForm,
                categoryId:this.categoryId
            }
            this.$api.kbase.getLawList(params).then(res=>{
                if(res.code==0){
                    this.list = res.data.list;
                    this.paging.total = res.data.total;
                }
            })
        },
        handleCurrentChange(val) {
            this.paging.pageNum = val;
            this.getData();
        }
    }
}
</script>
<style lang="scss" scoped>
.law{
    background: #F3F3F3;
    min-height: 100vh;
    padding-bottom: 48px;
    ::v-deep .wd-empty-data{
        padding-top: 148px;
        padding-bottom: 148px;
    }
    .kbase-banner{
        min-width: 1200px;
        img{
            width:100%;
        }
    }
}
.law-container{
    overflow: hidden;
    width:1200px;
    margin:0 auto;
}
.dxfSelect{
    .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
        background: rgba(255, 228, 228,.5);
    }
    .el-select-dropdown__item.selected{
        color: #E60003;
    }
}
.law-search{
    background: #fff;
    padding: 24px;
    margin:24px 0;
    border-radius: 4px;
    ::v-deep .el-form{
        margin-top: 12px;
    }
    ::v-deep {
        .el-input__inner{
            border-color: #d8d8d8;
        }
        .el-select .el-input.is-focus .el-input__inner,
        .el-select .el-input__inner:focus{
            border-color: #E60003;
        }
        .el-input__inner:focus{
            border-color: #E60003;
        }
        .el-form-item__content{
            width: 292px;
        }
        .el-select{
            width: 100%;
        }
        .el-form-item{
            margin-right: 40px;
            margin-bottom: 0;
            &:nth-last-child(1){
                margin-right: 0;
                .el-form-item__content{
                    width: 156px;
                }
                button{
                    width: 156px;
                    background: #E60003;
                    border-color: #E60003;
                    &:hover{
                        opacity: .8;
                    }
                }
            }
        }
    }
}
.law-category span{
    font-size: 16px;
    color: #666;
    line-height: 36px;
    margin-right: 32px;
    cursor: pointer;
    &:hover{
        color: #E03D2F;
    }
    &.active{
        color: #E03D2F;
        font-weight: 600;
    }
}
ul.lawList{
    display: flex;
    flex-wrap: wrap;
    li{
        background: #fff;
        width: 384px;
        margin-right: 24px;
        margin-bottom: 24px;
        border-radius: 4px;
        transition: all .4s ease-in-out;
        &:hover{
            cursor: pointer;
            box-shadow: 0px 2px 24px 0px rgba(128,54,54,0.1);
            transform: scale(1.03);
        }
        &:nth-child(3n){
            margin-right: 0;
        }
    }
    .top{
        height: 56px;
        line-height: 56px;
        border-bottom: 1px solid #EDEDED;
        padding:0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .bottom{
        padding:16px 16px 20px;
        p{
            display: flex;
            align-items: center;
        }
    }
    .nature{
        font-size: 16px;
        font-weight: 600;
        color: #E60003;
    }
    .status{
        font-size: 16px;
        padding:8px 12px;
        border-radius: 4px;
        height: 32px;
        box-sizing: border-box;
        line-height: 1;
    }
    .name{
        font-size: 16px;
        line-height: 28px;
        height: 56px;
        color: #666;
        margin-bottom: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 显示的行数 */
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .title{
        font-size: 14px;
        line-height: 24px;
        color: #666;
        white-space: nowrap;
    }
    .desc{
        font-size: 14px;
        line-height: 24px;
        color: #333;
        font-weight: 600;
        white-space: nowrap; /* 不换行 */
        overflow: hidden; /* 隐藏超出的内容 */
        text-overflow: ellipsis;
        flex-grow: 1;
    }
    .descPart{
        width: calc(100% - 106px);
        display: inline-block;
    }
    .will{
        background: #FFF6E3;
        color: #FF8D00;;
    }
    .pending{
        color: #E60003;
        background: #FFE4E4;
    }
    .fail{
        color: #666;
        background: #EDEDED;
    }
}
.pagination{
    text-align: center;
    margin-top: 8px;
    ::v-deep {
        .el-pager li{
            margin: 0 5px;
            border-radius: 6px;
        }
        .el-pagination .btn-prev,
        .el-pagination .btn-next{
            border-radius: 6px;
            padding: 0;
        }
        .el-pagination .btn-next{
            margin-left: 15px;
        }
        .el-pager li.active{
            background: #E60003;
            color: #fff;
        }
    }
}
</style>
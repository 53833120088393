// 法律法规性质
const lawNature = {
    1:{
        text:'国家法律',
    },
    2:{
        text:'地方法规、规章及规范性文件',
    },
    3:{
        text:'部门规章、规范性文件',
    },
    4:{
        text:'国家标准',
    },
    5:{
        text:'行业标准',
    },
    6:{
        text:'地方标准',
    },
    7:{
        text:'团体标准',
    },
    8:{
        text:'其他',
    }
}
// 法律法规状态
const lawStatus = {
    1:{
        text:'现行',
        cls:'pending'
    },
    2:{
        text:'即将实施',
        cls:'will'
    },
    3:{
        text:'作废',
        cls:'fail'
    },
}
// 隐患数据级别
const hazardDataCls = {
    1:{
        text:'一般隐患',
        cls:'general'
    },
    2:{
        text:'重大隐患',
        cls:'serious'
    },
}
// 隐患数据类型
const hazardDataType =  [
    {
        value:1,
        label:'基础管理类型',
        children:[
            {
                label:'安全生产目标',
                value:1
            },{
                label:'安全管理机构及人员',
                value:2
            },{
                label:'全员安全生产责任制',
                value:3
            },{
                label:'安全管理制度和安全操作规程',
                value:4
            },{
                label:'安全投入',
                value:5
            },{
                label:'安全教育培训',
                value:6
            },{
                label:'劳动防护用品',
                value:7
            },{
                label:'风险分级管控与隐患排查治理',
                value:8
            },{
                label:'相关方管理',
                value:9
            },{
                label:'职业健康管理',
                value:10
            },{
                label:'人员管理',
                value:11
            },{
                label:'设备设施管理',
                value:12
            },{
                label:'应急救援',
                value:13
            },{
                label:'其他',
                value:14
            }
        ]
    },
    {
        value:2,
        label:'现场管理类型',
        children:[
            {
                label:'用电安全',
                value:101
            },{
                label:'消防安全',
                value:102
            },{
                label:'建(构)筑物',
                value:103
            },{
                label:'场所环境',
                value:104
            },{
                label:'设备设施',
                value:105
            },{
                label:'作业行为',
                value:106
            },{
                label:'职业健康',
                value:107
            },{
                label:'危险化学品',
                value:108
            },{
                label:'安全标志',
                value:109
            },{
                label:'车辆管理',
                value:110
            },{
                label:'其他',
                value:111
            }
        ]
    }
]
// 事故级别
const accidentCls =  {
    1:{
        text:'特别重大事故',
        cls:'cls1'
    },
    2:{
        text:'重大事故',
        cls:'cls2'
    },
    3:{
        text:'较大事故',
        cls:'cls3'
    },
    4:{
        text:'一般事故',
        cls:'cls4'
    },
    5:{
        text:'其他分类标准',
        cls:'cls5'
    },
}
// 事故案例类型
const accidentType =  [
    {
        value:1,
        label:'自然灾害类',
        children:[
            {
                label:'水旱灾害',
                value:101
            },{
                label:'气象灾害',
                value:102
            },{
                label:'地震灾害',
                value:103
            },{
                label:'地质灾害',
                value:104
            },{
                label:'海洋灾害',
                value:105
            },{
                label:'生物灾害和森林草原火灾',
                value:106
            }
        ]
    },
    {
        value:2,
        label:'事故灾难类',
        children:[
            {
                label:'安全事故',
                value:201
            },{
                label:'交通运输事故',
                value:202
            },{
                label:'公共设施和设备事故',
                value:203
            },{
                label:'环境污染和生态破坏事件',
                value:204
            }
        ]
    },{
        value:3,
        label:'公共卫生事件类',
        children:[
            {
                value:301,
                label:'传染病疫情'
            },{
                label:'群体性不明原因疾病',
                value:302
            },{
                label:'食品安全和职业危害',
                value:303
            },{
                label:'动物疫情',
                value:304
            },{
                label:'其他严重影响公众健康和生命安全的事件',
                value:305
            }
        ]
    },{
        value:4,
        label:'社会安全事件类',
        children:[
            {
                label:'恐怖袭击事件',
                value:401
            },{
                label:'经济安全事件和涉外突发事件',
                value:402
            }
        ]
    }
]
  export {
    lawNature,
    lawStatus,
    hazardDataCls,
    hazardDataType,
    accidentType,
    accidentCls
  }
<template>
    <div class="law-detail">
        <div class="lawDetail-content">
            <div class="lawDetail-top module">
                <img :src="obj.hazardLevel==2?'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/serious-img.png':'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/kbase/general-img.png'" alt="" class="hazard-img-typ">
                <p class="title">隐患信息</p>
                <div class="item-line pt0">
                    <p>
                        <span class="sub">隐患类型：</span>
                        <span class="desc-type">{{ getText(obj.hazardType1)?.label }} - {{ getText(obj.hazardType2)?.label }}</span>
                    </p>
                </div>
                <div class="item-line">
                    <p class="sub">隐患描述：</p>
                    <p class="desc">{{ obj.hazardDesc }}</p>
                </div>
                <div class="item-line" v-if="obj.scenePhoto">
                    <p class="sub">现场照片：</p>
                    <div>
                        <el-image
                            fit="contain"
                            style="width: 206px; height: 115px"
                            :src="item"
                            :key="index"
                            v-for="(item,index) in JSON.parse(obj.scenePhoto)"
                            :preview-src-list="JSON.parse(obj.scenePhoto)">
                        </el-image>
                    </div>
                   
                </div>
                <div class="item-line" v-if="obj.possibleConsequences">
                    <p class="sub">隐患可能导致的后果：</p>
                    <p class="desc">{{ obj.possibleConsequences }}</p>
                </div>
            </div>
            <div class="lawDetail-middle module">
                <p class="title">整改信息</p>
                <div class="item-line pt0">
                    <p class="sub">整改措施：</p>
                    <p class="desc">{{ obj.rectificationMeasures }}</p>
                </div>
                <div class="item-line" v-if="obj.rectifiedPhoto && JSON.parse(obj.rectifiedPhoto) && JSON.parse(obj.rectifiedPhoto).length">
                    <p class="sub">整改后照片：</p>
                    <div>
                        <el-image
                            fit="contain"
                            style="width: 206px; height: 115px"
                            :src="item"
                            :key="index"
                            v-for="(item,index) in JSON.parse(obj.rectifiedPhoto)"
                            :preview-src-list="JSON.parse(obj.rectifiedPhoto)">
                        </el-image>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {hazardDataType} from './../kbase.data'
export default{
    data(){
        return{
            hazardDataType,
            obj:{},
        }
    },
    created(){
        this.getDetail();
    },
    methods:{
        getDetail(){
            this.$api.kbase.getHazardDetail({
                hazardId:this.$route.query.hazardId
            }).then(res=>{
                this.obj = res.data;
            })
        },
        getText(params){
            const obj = this.treeFindIdPath(
                this.hazardDataType,
                (data) => data.value == params
            );
            return obj
        },
        treeFindIdPath(tree, func, path = {}) {
            if (!tree) return {};
            for (const data of tree) {
                path = Object.assign({}, data);
                if (func(data)) return path;
                if (data.children) {
                    const findChildren = this.treeFindIdPath(data.children, func, path);
                    if (func(findChildren)) return findChildren;
                }
                path = {};
            }
            return path;
        }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-image{
    margin-top: 16px;
    background: rgba(0,0,0,.5);
    margin-right: 24px;
    &:nth-child(5n){
        margin-right: 0;
    }
}
.law-detail{
    min-height: 100vh;
    box-sizing: border-box;
    background: #F3F3F3;
    padding-bottom: 24px;
}
.lawDetail-middle{
    p{
        font-size: 18px;
        line-height: 30px;
    }
}
.lawDetail-content{
    overflow: hidden;
    .lawDetail-top{
        position: relative;
    }
    .hazard-img-typ{
        position: absolute;
        right: 32px;
        top: 0;
        width: 159px;
    }
    .module{
        background: #fff;
        border-radius: 8px;
        padding:24px 24px 32px;
        width:1200px;
        box-sizing: border-box;
        margin:24px auto;
    }
    .name{
        font-size: 18px;
        line-height: 30px;
        margin-bottom: 32px;
        color: #333;
    }
    .item-line{
        border-bottom: 1px solid #E9E9E9;
        padding: 24px 0px;
        margin: 0 13px;
        &:nth-last-child(1){
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    .pt0{
        padding-top: 0;
    }
    .bordernone{
        border-bottom: none;
        padding-bottom: 0;
    }
    .desc-type{
        color: #333;
        font-size: 18px;
    }
    .sub{
        font-size: 18px;
        color: #666;
    }
    .desc{
        margin-top: 16px;
        background: #F2F2F2;
        color: #333;
        font-size: 18px;
        line-height: 30px;
        padding: 16px;
        border-radius: 8px;
    }
    .title{
        font-size: 24px;
        font-weight: 600;
        color: #333;
        position: relative;
        padding-left: 13px;
        margin-bottom: 30px;
        &::after{
            content: '';
            width: 5px;
            height: 20px;
            background: #E60003;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
</style>
const urlName = '/standard-saas/v1'

export default API => ({
    /**
  * 法律法规
  */
    getLawList (params) {
        return API({
            url: `${urlName}/law/user/getLawList`,
            method:'post',
            params
        });
    },
    // 法律法规详情
    getLawDetail (params) {
        return API({
            url: `${urlName}/law/getLawDetail`,
            params
        });
    },
    // 法律法规分类
    getLawCategory () {
        return API({
            url: `${urlName}/law/user/getLawCategory`,
        });
    },
    // 隐患数据列表
    hazardList (params) {
        return API({
            url: `${urlName}/hazard/user/hazardList`,
            method:'post',
            params
        });
    },
    // 隐患数据详情
    getHazardDetail (params) {
        return API({
            url: `${urlName}/hazard/getHazardDetail`,
            params
        });
    },
    // 事故案例列表
    caseList (params) {
        return API({
            url: `${urlName}/case/user/caseList`,
            method:'post',
            params
        });
    },
    getCaseDetail (params) {
        return API({
            url: `${urlName}/case/getCaseDetail`,
            params
        });
    },
})
